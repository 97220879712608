import { useSegment } from '../useSegment'
import type { BaseEvent } from './base'

interface EventData {
  experimentName: string
  experimentVariant: string
}

export const useExperimentStartedEvent: BaseEvent<EventData> = (analytics = useSegment) => {
  const send = ({ experimentName, experimentVariant }: EventData): void => {
    analytics().track('Experiment Started', {
      experiment_name: experimentName,
      experiment_variant: experimentVariant,
    })
  }

  return {
    send,
  }
}
