import { DEFAULT_COUPON_CODE } from '@lyka/constants'
import { atom } from 'nanostores'
import { useCookies } from './useCookies'
import { useFlagmith } from './useFlagsmith'
import { useLykaApi } from '@/services/lyka-api'

interface MultiBoxDiscount {
  box: number
  discount: number
}

export interface Coupon {
  code: string
  discount: number
  referrer?: string
  multi_box_discount_enabled: boolean
  multi_box_discounts: MultiBoxDiscount[]
}

const state = (): Partial<{
  loaded: boolean
  coupon?: Coupon
}> => ({
  loaded: false,
  coupon: undefined,
})

const $state = atom(state())

interface InvalidCouponResponse {
  data: []
  message: string
}

interface ValidCouponResponse {
  data: {
    coupon_code: string
    discount: number
    referrer: string | null
    multi_box_discount_enabled: boolean
    multi_box_discounts: MultiBoxDiscount[]
  }
  message: string
}

const saveCouponToCookie = (coupon: Coupon): void => {
  const ONE_YEAR_SECONDS = 365 * 24 * 60 * 60
  const COUPON_COOKIE_NAME = 'coupon' as const
  const REFERRER_COOKIE_NAME = 'referrer' as const

  useCookies().set(COUPON_COOKIE_NAME, coupon.code, ONE_YEAR_SECONDS)

  if (coupon.referrer) {
    useCookies().set(REFERRER_COOKIE_NAME, coupon.code, ONE_YEAR_SECONDS)
  }
}

const getCouponData = async (couponCode: string): Promise<Coupon | undefined> => {
  try {
    const { data } = await useLykaApi().post<InvalidCouponResponse | ValidCouponResponse>('coupons/validate', {
      couponCode,
    })

    // If data is an empty array it means the coupon is invalid
    if (Array.isArray(data)) {
      return undefined
    }

    return {
      code: data.coupon_code,
      discount: data.discount,
      referrer: data.referrer ?? undefined,
      multi_box_discount_enabled: data.multi_box_discount_enabled,
      multi_box_discounts: data.multi_box_discounts,
    }
  } catch {
    return undefined
  }
}

const DEFAULT_COUPON_FLAG = 'experiment-coupon'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useCoupons = () => {
  const getCouponFromFlag = async (): Promise<Coupon | undefined> => {
    const code = await useFlagmith().getFlag(DEFAULT_COUPON_FLAG)

    if (code && typeof code === 'string') {
      const coupon = await getCouponData(code)

      return coupon
    }
  }

  const getDefaultCoupon = async (): Promise<Coupon | undefined> => {
    const code = DEFAULT_COUPON_CODE

    const coupon = await getCouponData(code)

    return coupon
  }

  const getCouponFromURL = async (): Promise<Coupon | undefined> => {
    const COUPON_PARAM = 'cp'

    const params = new URLSearchParams(window.location.search)
    const code = params.get(COUPON_PARAM)

    if (code) {
      const coupon = await getCouponData(code)

      if (coupon) {
        saveCouponToCookie(coupon)
      }

      return coupon
    }

    return undefined
  }

  const load = async (): Promise<void> => {
    // First try to get coupon from the URL
    let coupon: Coupon | undefined = await getCouponFromURL()

    // Next try to get coupon from the flag
    if (!coupon) {
      coupon = await getCouponFromFlag()
    }

    // Finally fallback to the default coupon
    if (!coupon) {
      coupon = await getDefaultCoupon()
    }

    if (coupon) {
      $state.set({
        loaded: true,
        coupon,
      })
    } else {
      $state.set({
        loaded: true,
      })
    }
  }

  const ready = (): Promise<Coupon | undefined> => {
    return new Promise((resolve) => {
      $state.subscribe(({ coupon, loaded }) => {
        if (loaded) {
          resolve(coupon)
        }
      })
    })
  }

  return {
    load,
    ready,
  }
}
