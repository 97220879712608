const getUrl = (path: string): string => {
  return new URL(path, import.meta.env.PUBLIC_LYKA_API_URL).toString()
}

const request = async <T>(path: string, options?: RequestInit): Promise<T> => {
  const url = getUrl(path)

  const headers = new Headers({
    Accept: 'application/json',
    ...options?.headers,
  })

  const response = await fetch(url, {
    ...options,
    headers,
  })

  if (response.ok) {
    return response.json()
  }

  throw new Error(response.statusText)
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useLykaApi = () => {
  const get = async <T>(path: string): Promise<T> => {
    return request(path)
  }

  const post = async <T>(path: string, data: object): Promise<T> => {
    return request(path, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
  }

  return {
    get,
    post,
  }
}
