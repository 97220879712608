import { type DeferredPromise } from '@lyka/utils/src/defer'
import type flagsmith from 'flagsmith'
import { atom } from 'nanostores'

declare global {
  interface Window {
    flagsmith?: typeof flagsmith
    experiments: DeferredPromise<Map<string, string>>
  }
}

export type Experiments = Map<string, string | boolean | number>

interface State {
  loaded: boolean
  error: boolean
  experiments: Experiments
}

const state = (): State => ({
  loaded: false,
  error: false,
  experiments: new Map(),
})

type Flags = Record<string, unknown>

export const $experiments = atom(state())

// For testing
export const resetExperiments = (): void => {
  $experiments.set(state())
}

export const showVariant = (experiments: Experiments, experiment: string, variant: string): boolean => {
  if (experiments.get(experiment) === variant) {
    return true
  }

  if (!experiments.has(experiment) && variant === 'control') {
    return true
  }

  return false
}

const EXPERIMENT_PREFIX = 'experiment-' as const

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useExperiments = () => {
  const fail = (): void => {
    if ($experiments.get().loaded) {
      return
    }

    const experiments: Experiments = new Map()

    $experiments.set({
      error: true,
      loaded: true,
      experiments,
    })
  }

  const fromFlags = (flags: Flags): Experiments => {
    const experiments = new Map()

    for (const flag in flags) {
      if (flag.startsWith(EXPERIMENT_PREFIX)) {
        const variant = flags[flag]

        experiments.set(flag, variant)
      }
    }

    $experiments.set({
      error: false,
      loaded: true,
      experiments,
    })

    return experiments
  }

  const ready = (): Promise<Experiments> => {
    return new Promise((resolve) => {
      $experiments.subscribe(({ experiments, loaded }) => {
        if (loaded) {
          resolve(experiments)
        }
      })
    })
  }

  return {
    fromFlags,
    fail,
    ready,
  }
}
