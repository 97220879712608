const init = async (anonymousUserId: string): Promise<void> => {
  await window.flagsmith?.init({
    environmentID: import.meta.env.PUBLIC_FLAGSMITH_ENVIRONMENT_KEY,
    identity: anonymousUserId,
    onError: (error) => {
      console.error('error', error)
    },
  })
}

type Flag = string | boolean | number

type Flags = Record<string, Flag>

export type FlaggingService = () => {
  load: (anonymousUserId: string) => Promise<Flags>
  getFlags: () => Promise<Flags>
  getFlag: (key: string) => Promise<Flag | undefined>
}

let ready: () => void

const loading = new Promise<void>((resolve) => (ready = resolve))

export const useFlagmith: FlaggingService = () => {
  const getFlags = async (): Promise<Flags> => {
    const flags = window.flagsmith?.getAllFlags() ?? {}
    const enabled: Flags = {}

    for (const [k, v] of Object.entries(flags)) {
      if (v.enabled && v.value) {
        enabled[k] = v.value
      }
    }

    return enabled
  }

  const getFlag = async (key: string): Promise<Flag | undefined> => {
    await loading

    if (window.flagsmith?.hasFeature(key)) {
      return window.flagsmith?.getValue(key) ?? undefined
    }
  }

  const load = async (anonymousUserId: string): Promise<Flags> => {
    await init(anonymousUserId)

    ready()

    return getFlags()
  }

  return {
    load,
    getFlags,
    getFlag,
  }
}
