type CookieProperties = Record<string, string | number | boolean>

export const asCookieString = (cookie: CookieProperties): string => {
  return Object.entries(cookie)
    .map(([k, v]) => {
      if (v === true) {
        return k
      }

      return [k, v].join('=')
    })
    .join('; ')
}

type DocumentWithCookie = Pick<Document, 'cookie'>

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useCookies = (doc: DocumentWithCookie = document, domain = location.hostname) => {
  const set = (name: string, value: string, maxAgeSeconds?: number): void => {
    const cookie: CookieProperties = {
      [name]: value,
      Domain: domain,
      Path: '/',
      Secure: true,
    }

    if (maxAgeSeconds) {
      cookie['Max-Age'] = maxAgeSeconds
    }

    doc.cookie = asCookieString(cookie)
  }

  const get = (name: string): string | undefined => {
    return doc.cookie
      .split('; ')
      .find((row) => row.startsWith(`${name}=`))
      ?.split('=')[1]
  }

  const remove = (name: string): void => {
    doc.cookie = `${name}=; Expires=${new Date(0).toUTCString()}; Domain=${location.hostname}; path=/; Secure;`
  }

  return {
    set,
    get,
    remove,
  }
}
